.custom-table .ant-table-thead {
  background-color: lightgray !important;
  /* border-radius: 7px; */
  /* background-color: transparent; */
}

.custom-table .ant-table-content {
  /* background-color: lightgray; */
  /* border: 1px lightgray solid; */
  border-radius: 9px;
}

.custom-table table {
  border-radius: 9px !important;
  border: 1px lightgray solid;
}

.custom-input-number .ant-input-number-handler-wrap {
  display: none;
}

/* .ant-switch-checked {
  background-color: red !important;
} */

[aria-checked="true"] {
  background-color: red !important;
}

[aria-checked="false"] {
  background-color: grey !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
