/*input standar*/
.input-name {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.input-name>input[type=text] {
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
}

.underline-animation {
    transition: all 0.5s;
    display: inline-block;
    bottom: 0;
    left: -100%;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: red;
}

.input-name>input[type=text]:focus+.underline-animation {
    left: 0;
}

/*input title*/

.input-title {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.input-title>input[type=text] {
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
}

.underline-animation {
    transition: all 0.5s;
    display: inline-block;
    bottom: 0;
    left: -100%;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: red;
}

.input-title>input[type=text]:focus+.underline-animation {
    left: 0;
}

.input-title>input::placeholder {
    color: black;
}

/*input answer*/
.input-answer {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.input-answer>input[type=text] {
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
}

.underline-animation {
    transition: all 0.5s;
    display: inline-block;
    bottom: 0;
    left: -100%;
    position: absolute;
    width: screen;
    height: 2px;
    background-color: transparent;
}

.input-answer>input[type=text]:focus+.underline-animation {
    left: 0;
}