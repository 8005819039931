#childTableQuizNoHover .ant-table-tbody>tr.ant-table-row:hover>td {
    background: unset;
}

#editorTinyMce button {
    border-radius: 5px;
}

#editorTinyMce button:active {
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
    