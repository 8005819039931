.ant-tabs-nav-list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    color: #4C4C4C;
    border: none;
    
}

@media (min-width: 768px) {
    .ant-tabs-nav-list {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%) !important;
        color: #4C4C4C;
        border: none;
    } 
}

.ant-tabs-tab {
    color: #4C4C4C;
    border: none;
}

.ant-tabs-tab-active {
    color: #D00000;
    border:none;
    border-bottom: none;
}