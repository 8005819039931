.react-slide {
    margin-right: 20px;
}

.slick-current {
    color: #D00000;
    border-bottom: 3px solid #D00000;
}

.react-slide-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slick-current .react-slide-item > span{
    color: #D00000 !important;
}

/* .slick-track {
    width: auto !important;
} */