.ant-progress .ant-progress-bg {
  position: relative;
  background-color: #dd0000;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.theCard {
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}

/* .theCard {
    transform: rotateY(180deg);
} */

.cardFront {
  background: white;
  backface-visibility: hidden;
}


@media (width >= 768px) {
  .talentbar-card {
    width: 450px;
    height: 250px;
    flex-shrink: 0;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid rgba(168, 168, 168, 0.4);
  }

  .recent-certificate-item {
    border-radius: 20px;
    border: 1px solid rgba(168, 168, 168, 0.4);
    background: var(--White, #fff);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: col;
    align-items: center;
    gap: 30px;
    padding: 20px;
  }
}
