[aria-checked="true"] {
  background-color: green !important;
}

[aria-checked="false"] {
  background-color: grey !important;
}

.ant-message {
  z-index: 999999999;
}
.ant-select-dropdown, .ant-picker-dropdown {
  z-index: 999999999;
}

.add-form-client-project {
  .ant-form-item-label  {
    display: flex;
    justify-content: flex-start;
  }
}