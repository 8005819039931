.wrapper-percentage-event {
  background-image: url("../../../../assets/icons/StarPercentage.png");
  background-repeat: no-repeat;
  background-size: 35px 35px;
  padding-top: 9px;
  padding-left: 6px;
  background-position: center;
  font-size: 9pt;
}

#masterEventList .ant-table-cell {
  background-color: white;
}

#masterEventList .ant-table-expand-icon-col {
  width: 10px !important;
}

#masterEventList .placeholder-select {
  color: #a8a8a8 !important;
}
button.ant-switch.switch-background {
  background-color: #ecf1f4 !important;
}

button.ant-switch-checked.switch-background {
  background-color: #1677ff !important;
}
.task-number-list {
  vertical-align: baseline;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.col-task-list .ant-form-item {
  margin-bottom: 0;
  width: 120px;
}

.col-task-type .ant-form-item {
  margin-bottom: 0;
}

.ant-picker-ok .ant-btn {
  background-color: #dd0000 !important;
  color: white;
}

#formDataEvent .ant-tag-close-icon {
  margin-left: 10px;
  margin-bottom: 6px;
}

.ant-pagination .ant-pagination-item-active a {
  color: #dd0000;
}

.ant-pagination .ant-pagination-item-active a:hover {
  color: #dd0000;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #dd0000;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #dd0000;
}

@media (min-width: 1280px) {
  #DetailEventPage.container {
    max-width: 1200px;
  }
}
