@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .accent-red {
    accent-color: red;
  }
}

:root {
  --dark-grey: #4c4c4c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-header-1 {
  color: var(--Dark-Grey, #4c4c4c);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.font-20-regular {
  color: var(--dark-grey);
  font-size: 20px;
  line-height: 28px;
}

.font-20-regular-normal {
  color: var(--Dark-Grey, #4c4c4c);
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.font-20-bold-500 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.font-24-bold-600 {
  color: var(--dark-grey);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.font-16-bold-500 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.card-shadow {
  border-radius: 20px;
  border: 1px solid rgba(168, 168, 168, 0.4);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

/* .ant-table-wrapper {
  overflow-x: auto !important;
} */

.ant-table {
  white-space: nowrap !important;
}

.ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remark-cell {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 0;
}

@layer utilities {
  .page-header-title {
    font-weight: 700;

    @media (min-width: 768px) {
      /* Apply styles for screens with a maximum width of 640px (mobile) */
      font-size: 40px;
    }
  }
}

.header-gradient {
  background: linear-gradient(
    180deg,
    #dd0000 0.54%,
    rgba(221, 0, 0, 0.6706) 44.66%,
    rgba(243, 63, 63, 0.488423) 62.52%,
    rgba(247, 130, 130, 0.316082) 75.71%,
    rgba(255, 255, 255, 0) 99.91%
  );
}

.tabs-button {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 35.26%,
    #ffffff 65.26%,
    rgba(255, 255, 255, 0) 101.05%
  );
}

.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100%;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.modal-header h2 {
  text-align: center;
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.custom-modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: max-content;
}

.talk-bubble {
  margin: 30px -30px 0px 0px;
  display: inline-block;
  position: relative;
  width: 280px;
  height: auto;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .talk-bubble {
    margin: 20px -20px 0px 0px;
    width: 200px;
  }
}

.round {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: auto;
  bottom: 10px;
  border: 13px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

.talktext {
  padding: 1em;
  text-align: left;
  line-height: 3em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.text-1c5rem {
  font-size: 1.5rem;
}

.attendance-home-img {
  padding: 30px 30px;
}

.att-box {
  border-color: #ffd233;
  border-width: 1px;
  background-color: rgba(255, 210, 51, 0.2);
}

.att-desc-color {
  color: #8c6d00;
}

.att-rec-title-color {
  color: #4c4c4c;
}

.att-rec-desc-color {
  color: #a8a8a8;
}

.searchbox input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.search-select-box .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #dd0000;
  border-color: #dd0000;
}

.search-select-box input[type="checkbox"]:hover {
  color: #dd0000;
}

.search-select-box .checkbox:hover {
  background-color: #dd0000 !important;
  border-color: #dd0000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red;
  border-color: red;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-200 {
  min-width: 200px;
}

@media (max-width: 768px) {
  .ant-table-wrapper {
    overflow-x: auto;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 1800px;
  }
}
@media (min-width: 1536px) and (max-width: 1899px) {
  .container {
    max-width: 1500px;
  }
}

@media (max-width: 576px) {
  .form-event {
    @apply mt-10;
  }
}
